<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('aqvsfocfactor.title')">
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-select
								attach="aqenginetypesid"
								:label="this.$t('aqvemisionfactor.menginetype')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="aqenginetypes"
								:modelFormMapping="{ aqenginetypesid: 'menginetype' }"
								:itemsToSelect="enginetypesItemsToSelect"
								itemValue="aqenginetypesid"
								itemText="name"
							>
							</pui-select>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								attach="aqtiersid"
								:label="this.$t('aqvemisionfactor.mtier')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="aqtiers"
								:modelFormMapping="{ aqtiersid: 'mtier' }"
								:itemsToSelect="tiersItemsToSelect"
								itemValue="aqtiersid"
								itemText="name"
							>
							</pui-select>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								attach="aqenginespeedsid"
								:label="this.$t('aqvemisionfactor.menginespeed')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="aqenginespeeds"
								:modelFormMapping="{ aqenginespeedsid: 'menginespeed' }"
								:itemsToSelect="enginespeedsItemsToSelect"
								itemValue="aqenginespeedsid"
								itemText="name"
							>
							</pui-select>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								attach="aqfueltypesid"
								:label="this.$t('aqvemisionfactor.mfueltype')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="aqfueltypes"
								:modelFormMapping="{ aqfueltypesid: 'mfueltype' }"
								:itemsToSelect="fueltypesItemsToSelect"
								itemValue="aqfueltypesid"
								itemText="name"
							>
							</pui-select>
						</v-flex>
						<v-flex xs12 md4>
							<pui-text-field
								:label="$t('aqvsfocfactor.technology')"
								v-model="model.technology"
								required
								toplabel
								maxlength="30"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								attach="aqpollutantsid"
								:label="this.$t('aqvemisionfactor.mpollutant')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="aqpollutants"
								:modelFormMapping="{ aqpollutantsid: 'mpollutant' }"
								:itemsToSelect="pollutantsItemsToSelect"
								itemValue="aqpollutantsid"
								itemText="name"
							>
							</pui-select>
						</v-flex>
						<v-flex xs12 md4>
							<pui-number-field
								:label="$t('aqvsfocfactor.auxconsumptionfactor')"
								v-model="model.auxconsumptionfactor"
								required
								toplabel
								maxlength="8"
							></pui-number-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-number-field
								:label="$t('aqvsfocfactor.mainconsumptionfactor')"
								v-model="model.mainconsumptionfactor"
								required
								toplabel
								maxlength="8"
							></pui-number-field>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('aqvsfocfactor.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
export default {
	name: 'aqvsfocfactorform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'aqvsfocfactor',
			model: 'aqvsfocfactor'
		};
	},
	computed: {
		enginetypesItemsToSelect() {
			return [{ enginetypesid: this.model.enginetypesid }];
		},
		tiersItemsToSelect() {
			return [{ aqtiersid: this.model.aqtiersid }];
		},
		enginespeedsItemsToSelect() {
			return [{ aqenginespeedsid: this.model.aqenginespeedsid }];
		},
		fueltypesItemsToSelect() {
			return [{ aqfueltypesid: this.model.aqversionemisionfactorid }];
		},
		pollutantsItemsToSelect() {
			return [{ aqpollutantsid: this.model.aqpollutantsid }];
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	methods: {}
};
</script>
